

<template>
    <main>
      <loading-overlay
          :active="Loading"
          :is-full-page="true"
          loader="bars"
        />
    <CCard>
      <CCardHeader class="text-center bg-dark text-white">
        <b>{{$t('label.moduleRoleList')}} - SIGET-MOBILE</b>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <CCard>
              <CCardHeader color="dark" text-color="white">
                <h5>{{$t('label.filter')}}</h5>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol sm="6" lg="3">
                    <CSelect
                      :label="$t('label.moduleType')"
                      :placeholder="$t('label.select')"
                      :horizontal="{label:'col-sm-9 col-lg-5',input:'col-sm-11 col-lg-7'}"
                      addLabelClasses="text-right"
                      :options="UserRoleOptions"
                      :value="UserRoleId"
                      @change="FilterUserRol($event)"
                    />
                  </CCol>
                  <CCol sm="6" lg="3">
                    <CSelect
                      :label="$t('label.role')"
                      :placeholder="$t('label.select')"
                      :horizontal="{label:'col-sm-9 col-lg-5',input:'col-sm-11 col-lg-7'}"
                      addLabelClasses="text-right"
                      :options="RolListOptions"
                      :value="RolId"
                      @change="FilterRol($event)"
                    />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="12">
            <CCard>
              <CCardHeader color="secondary" text-color="white">
                <h5 class="float-left">{{$t('label.moduleList')}}</h5>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol sm="12">
                    <dataTableExtended
                      class="align-center-row-datatable"
                      :items="ComputedModuleList"
                      :fields="FieldsModuleList"
                      :table-filter="tableText.tableFilterText"
                      :items-per-page-select="tableText.itemsPerPageText"
                      :noItemsView="tableText.noItemsViewText"
                      :items-per-page="tableText.itemsPerPage"
                      small
                      sorter
                      pagination
                    >
                      <template #checked-header>
                        <td style="border-top:none">
                          <CInputCheckbox
                            custom
                            class="checkbook-filter mr-2"
                            :checked="SelectedAllModulo"
                            @update:checked="selectAllModulos($event)"
                          />
                        </td>
                      </template>
                      <template #checked="{item}">
                        <td>
                          <CInputCheckbox
                            custom
                            class="checkbook-filter ml-1"
                            :checked="item.checked"
                            @update:checked="addRemoveId(item,'padre')"
                          />
                        </td>
                      </template>
                      <template #show_details="{item}">
                        <td class="centen-cell">
                          <CButton
                            color="watch"
                            square
                            v-c-tooltip="{
                            content: $t('label.toView')+' '+$t('label.level'),
                              placement: 'top-end',
                            }"
                            size="sm"
                            @click="toggleLevel1(item)" 
                            v-if="item.ModuleDeviceChildJson.length > 0"
                          >
                            <CIcon name="eye" />
                          </CButton>
                        </td>
                      </template>
                      <template #details="{item}">
                        <CCollapse
                          :show="Boolean(item._toggled)"
                          :duration="collapseDuration"
                          > 
                          <CCardBody>
                            <CCol sm="12" lg="12">
                              <!-- Datatable sudmodule -->
                              <dataTableExtended
                                class="align-center-row-datatable table-lv-2"
                                :items="ComputedModuleSubList"
                                :fields="FieldsModuleSubList"
                                :items-per-page="5"
                                sorter
                                pagination
                                :table-filter="tableText.tableFilterText"
                                :items-per-page-select="tableText.itemsPerPageText"
                                :noItemsView="tableText.noItemsViewText"                                    
                                >
                                <template #loading>
                                      <loading/>
                                </template>
                                <template #checked="{item}">
                                  <td>
                                    <CInputCheckbox
                                      custom
                                      class="checkbook-filter ml-1"
                                      :checked="item.checked"
                                      @update:checked="addRemoveId(item,'hijo')"
                                    />
                                  </td>
                                </template>
                              </dataTableExtended>
                            </CCol>
                          </CCardBody>
                        </CCollapse>
                      </template>
                    </dataTableExtended>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
            <CCol sm="12" class="text-right">
              <CButton color="add" :disabled="isSubmit" @click="saveChanges()">
                <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
              </CButton>
            </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </main>
  </template>
  <script>
  import GeneralMixin from '@/_mixins/general'
  
  function data() {
    return {
      CollapseId:'',
      RoleId:'',
      UserRoleId: '',
      RolId: '',
      UserRoleList: [],
      RolList: [],
      SelectedAllModulo: false,
      ModuleList: [],
      ModuleListLevel2: [],
      Loading: false, 
      collapseDuration: 0,   
      isSubmit:false,
      Collapse: false,
      ModuleDeviceId:'',
      Status:0,
      ArrIds:[],
      CollapseOpen: '',
    };
  }
  
  //methods
  
  function closeShow(item, level) {
    let moduleOpen;
    if (level == 1) {
      moduleOpen = this.ComputedModuleList.find((val) => val._toggled == true);
    } else if (level == 2) {
      moduleOpen = this.ComputedModuleSubList.find((val) => val._toggled == true);
    }
    if (moduleOpen != undefined && moduleOpen.ModuleDeviceId != item.ModuleDeviceId) {
      moduleOpen._toggled = !moduleOpen._toggled;
    }
  }
  
  async function toggleLevel1(item) {
    this.Loading = true;
    await this.closeShow(item, 1);
  
    const modulePadre = this.ModuleList.find(
      (val) => val.ModuleDeviceId === item.ModuleDeviceId
    );
   
    this.ModuleListLevel2 = modulePadre.ModuleDeviceChildJson;
  
    this.CollapseOpen = modulePadre?.ModuleDeviceId ? modulePadre.ModuleDeviceId : '';
  
    item._toggled = !item._toggled;
    if (item._toggled == true) {
        this.CollapseId = item.ModuleDeviceId;
      }else{ 
        this.CollapseId = '';
      };
    
    this.collapseDuration = 300;
    this.$nextTick(() => {
      this.collapseDuration = 0;
      this.loading = false;
      this.Loading = false;
    });
  }
  
  function selectAllModulos(event) {
    this.ArrIds = [];
  
    if (event) {
      this.SelectedAllModulo = true;
      //SELECCIONA TODOS LOS ITEM NIVEL I
      this.ModuleList.map((item) => {
        this.ArrIds.push({id: item.ModuleDeviceId, role: item.ModuleDeviceRoleId ? item.ModuleDeviceRoleId : ''});  
      });
      //SELECCIONA TODOS LOS ITEM NIVEL II
      this.ModuleListLevel2.map((item) => {
        this.ArrIds.push({id: item.ModuleDeviceId, role: item.ModuleDeviceRoleId ? item.ModuleDeviceRoleId : ''});  
      });
    }else{
      this.SelectedAllModulo = false;
    }
  }
  
  
  function addRemoveId(item, tipo) {
    let bool = this.ArrIds.filter((e) => e.id == item.ModuleDeviceId)
  
    if( bool.length == 0 ){
      //ADD
      this.ArrIds.push({id: item.ModuleDeviceId, role: ''});
      
      //SI SE TILDA UN PADRE SE DEBEN MARCAR TODOS LOS HIJOS
      if ( tipo == 'padre'){
        this.ModuleListLevel2.map((j) => {
          this.ArrIds.push({id: j.ModuleDeviceId, role: ''});
        });
      }
  
      //SI SE TILDA UN HIJO SE DEBE MARCAR EL PADRE
      if ( tipo == 'hijo' && this.CollapseOpen != ''){
        let aux = this.ArrIds.filter((e) => e.id == this.CollapseOpen)
        aux.length == 0 && this.ArrIds.push({id: this.CollapseOpen, role: ''});
      }
    }
    else{
      //DELETE
      this.ArrIds = this.ArrIds.filter((e) => e.id !== item.ModuleDeviceId);
          
      //SI SE DESTILDA UN PADRE SE DEBE DESMARCAN LOS HIJOS
      if ( tipo == 'padre' && this.CollapseOpen != ''){
        
        this.ModuleListLevel2.map((j) => {
          this.ArrIds = this.ArrIds.filter((e) => e.id !== j.ModuleDeviceId);
        });
      }
    }
  
    this.SelectedAllModulo = false;  
  }
  
  function cleanDataSelect(){
    this.ArrIds = [];
    this.ModuleList = [];
    this.SelectedAllModulo = false;
    this.CollapseId=false;
  }
  
  function FilterUserRol(event) {
    this.UserRoleId = event.target.value;
    this.getRolList();
  }
  
  function FilterRol(event) {
    this.RolId = event.target.value;
    this.getModuleDeviceList();
  }
  
  function getRolList() {
    if (this.UserRoleId!='') {
      this.Loading = true;
      this.$http
      .get('RoleSigetMobile-list')
      .then((response) => {
        if (response.data.data.length != 0) {
          this.RolList = response.data.data;
          this.RolId = this.RolList[0].RoleId;
          this.Loading = false;
          this.getModuleDeviceList();
        }
      })
      .catch((e) => {
        this.Loading = false;
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: e,
          type: 'error',
        });
      })  
    }else{
      this.RolList = [];
      this.ModuleList = [];
    }
  }
  function ListCargoType() {
    this.Loading = true;
    this.$http
      .get('TpModuleUserRole-list')
      .then(response => {
        let list = response.data.data;
        if (list.length != 0) {
          this.UserRoleList = list;
          this.UserRoleId = this.UserRoleList[0].TpModuleId;
          this.getRolList();
        }
      })
      .catch((e) => {
        this.Loading = false;
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: e,
          type: 'error',
        });
      })  
  }
  
  function getModuleDeviceList() {
    if ((this.UserRoleId!='') && (this.RolId!='')) {
      this.Loading = true;
      this.cleanDataSelect();
      this.$http
      .get('ModuleDeviceUserRole-list', {TpModuleId: this.UserRoleId, RoleId: this.RolId})
      .then((response) => {
        let list = response.data.data;
        this.ModuleList = response.data.data;
        this.Loading = false;
        
        list.map((item) => {
          if ( item.ModuleDeviceRoleId !== null ) {
            //PADRES
            this.ArrIds.push({id: item.ModuleDeviceId, role: item.ModuleDeviceRoleId});
            
            (item.ModuleDeviceChildJson).map((e) => {
              if ( e.ModuleDeviceRoleId !== null ) {  
                //HIJOS           
                this.ArrIds.push({id: e.ModuleDeviceId, role: e.ModuleDeviceRoleId});
              }
            });
          }
        });
      })
      .catch((e) => {
        this.Loading = false;
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: e,
          type: 'error',
        });
      })
    }
    else{
      this.ModuleList = [];
    }
  }
  
  function saveChanges(){
    let res = [];
    this.Loading = true;
  
    let json = [];
  
    this.ArrIds.map((e) => {
      json.push({
        ModuleDeviceId: e.id,
        RoleId: this.RolId,
      })
    })
  
    json=this.ArrIds.length==0 ? {RoleId: this.RolId, ModuleDeviceId: ""} : json
  
    this.$http.post("ModuleDeviceUserRole-insert", json, { root: 'ModuleDeviceUserRoleJson' })
      .then((response) => {
        res = [...response.data.data];
  
        this.$notify({
          group: 'container',
          title: '¡Exito!',
          text: res[0].Response,
          type: 'success',
        });
  
        this.getModuleDeviceList();
        this.Loading = false;
  
        this.cleanDataSelect();
      })
      .catch((err) => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: 'error',
        });
        this.Loading = false;
      });
  }
  
  function UserRoleOptions(){
    let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    if(this.UserRoleList.length === 0){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      var chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.UserRoleList.map(function(e){
        chart.push({
          value: e.TpModuleId,
          label: e['TpModuleName'+_lang],
        })    
      })
      return chart;
    }
  }
  
  function RolListOptions(){
    let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    if(this.RolList.length === 0){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      var chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.RolList.map(function(e){
        chart.push({
          value: e.RoleId,
          label: e['RoleName'+_lang],
        })    
        })
        return chart;
    }
  }
  
   function ComputedModuleList() {
    if (this.ModuleList.length > 0) {
      return this.ModuleList.map((item) => {
  
        if (this.CollapseId==item.ModuleDeviceId) {
          this.ModuleListLevel2 = item.ModuleDeviceChildJson;
        };
  
        let checkeds = this.ArrIds.filter((e) => e.id == item.ModuleDeviceId);
  
        return {
          ...item,
          _classes:'table-dark',
          _toggled: this.CollapseId ? (this.CollapseId==item.ModuleDeviceId ? true : false) : false,
          checked: (checkeds.length > 0),
        };
      });
    }
  }
  
  function ComputedModuleSubList() {
    if (this.ModuleListLevel2.length > 0) {
      return this.ModuleListLevel2.map((item) => {
        let checkeds = this.ArrIds.filter((e) => e.id == item.ModuleDeviceId);
  
        return {
          ...item,
          _toggled: false,
          checked: (checkeds.length > 0),
        };
      });
    }
  }
  
  function FieldsModuleList () {
    return[
      { key: 'checked',label: 'Sel',sorter: false, filter: false,_style:'width:3%;',},
      { key: 'ModuleNameDsEs',label: this.$t('label.ModuleName')+' '+'(ES)',_style:'width:20%;text-align:center;', _classes:"center-cell", },
      { key: 'ModuleNameDsEn',label: this.$t('label.ModuleName')+' '+'(EN)',_style:'width:20%;text-align:center;', _classes:"center-cell", },
      { key: 'ModuleRoute',label: this.$t('label.route'),_style:'width:25%;text-align:center;', _classes:"center-cell", },
      { key: 'Navegation',label: this.$t('label.navigation'),_style:'width:30%;text-align:center;', _classes:'center-cell',},
      { key: 'show_details',label: '',_style: 'min-width:45px;', sorter: false,}
    ]
  }
  
  function FieldsModuleSubList () {
    return[
      { key: 'checked',label:'',sorter: false,_style:'width:3%;',},
      { key: 'ModuleNameDsEs',label: this.$t('label.SubModuleName')+' '+'(ES)',_style:'width:20%;text-align:center;', _classes:"center-cell", },
      { key: 'ModuleNameDsEn',label: this.$t('label.SubModuleName')+' '+'(EN)',_style:'width:20%;text-align:center;', _classes:"center-cell", },
      { key: 'ModuleRoute',label: this.$t('label.route'),_style:'width:25%;text-align:center;', _classes:"center-cell", },
      { key: 'Navegation',label: this.$t('label.navigation'),_style:'width:30%;text-align:center;', _classes:'center-cell',},
    ]
  }
  
  
  
  
  export default {
    name: 'index',
    components: {},
    data,
    mixins:[GeneralMixin],
    methods: {
      toggleLevel1,
      selectAllModulos,
      saveChanges,
      FilterUserRol,
      FilterRol,
      getRolList,
      ListCargoType,
      getModuleDeviceList,
      addRemoveId,
      cleanDataSelect,
      closeShow,
    },
    computed: {
      ComputedModuleList,
      ComputedModuleSubList,
      FieldsModuleList,
      FieldsModuleSubList,
      UserRoleOptions,
      RolListOptions
    },
    mounted: ListCargoType, 
    
  }
  </script>
  
  <style lang="scss">
  
  .center-cell {
    text-align: center;
    vertical-align: middle;
  }
  
  </style>
  